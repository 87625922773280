<template>
  <div class="border-b border-gray-800 px-6 py-4">
    <div v-if="loading" class="flex flex-col">
      <BaseLoader class="m-auto h-5 w-5" />
    </div>
    <div v-else>
      <div class="flex flex-col space-y-2">
        <div class="flex items-center space-x-2">
          <span class="text-sm font-semibold capitalize text-gray-200">Choose a News Feed to set alerts on</span>
          <span v-if="required.alertType" class="block text-xs text-red-400">Required</span>
          <span v-else-if="duplicateAlert" class="block text-xs text-red-400">
            Alert with same feed already exists
          </span>
        </div>
        <BaseSelect
          id="news-feed-dropdown"
          button-width-class="w-full"
          dropdown-width-class="w-full"
          v-model="alertData.news_feed_id"
          :options="feeds"
          :searchable="true"
          :placeholder="'Select News Feed'"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import useEmitter from '@/composeables/emitter';
import useHttp from '@/composeables/http';
import { useStore } from 'vuex';

const emitter = useEmitter();
const $http = useHttp();
const $store = useStore();

const props = defineProps({
  alert: { type: Object, default: () => null }
});

const loading = ref(true);
const feeds = ref([]);
const required = ref({ alertType: false });
const alertData = ref({ news_feed_id: null });
const duplicateAlert = ref(false);

function showValidationError(data) {
  if (data.alertType) {
    required.value.alertType = data.alertType;
    setTimeout(() => {
      required.value.alertType = false;
    }, 5000);
  } else if (data.duplicateAlert) {
    duplicateAlert.value = data.duplicateAlert;
    setTimeout(() => {
      duplicateAlert.value = false;
    }, 5000);
  }
}
function editData() {
  if (props.alert) {
    alertData.value = props.alert.config;
  }
}
async function fetchNewsFeeds() {
  const response = await $http.get('/news_feeds');
  // allowing on chain feed for alerts creation.
  let defaultFeeds = $store.getters.defaultFeeds.filter(f => f != 'On-Chain Signal');
  feeds.value = response.data.news_feeds
    .filter(x => !defaultFeeds.includes(x.name))
    .map(item => {
      return {
        id: item.id,
        label: item.name
      };
    })
    .filter(e => e.label != null);
  loading.value = false;
}
watch(
  alertData,
  () => {
    emitter.$emit('config:alert', { config: alertData.value });
  },
  { deep: true, immediate: true }
);
onMounted(() => {
  emitter.$on('NewsForm', data => {
    showValidationError(data);
  });
  editData();
  fetchNewsFeeds();
});
onBeforeUnmount(() => {
  emitter.$off('NewsForm');
});
</script>
